<template>
  <page-header-wrapper :showInModal="showInModal">
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="资源" prop="resourceId">
                <a-input v-model="queryParam.perms" placeholder="请输入资源(uri)" allow-clear />
              </a-form-item>
            </a-col>
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="Client Id" prop="clientId">
                <a-input v-model="queryParam.clientId" placeholder="请输入Client Id" allow-clear />
              </a-form-item>
            </a-col> -->
            <!-- <template v-if="advanced"> -->
            <a-col :md="8" :sm="24">
              <a-form-item label="每分钟访问api的次数" prop="numberLimit">
                <a-input v-model="queryParam.numberLimit" placeholder="请输入每分钟访问api的次数" allow-clear />
              </a-form-item>
            </a-col>
            <!-- </template> -->
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <!-- <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a> -->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button
            type="primary"
            @click="$refs.createForm.handleAdd()"
            v-hasPermi="['tenant:openapi:clientResource:add']"
          >
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:openapi:clientResource:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:openapi:clientResource:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:openapi:clientResource:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :clientInfo="clientInfo" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:openapi:clientResource:edit']" />
          <a
            @click="$refs.createForm.handleUpdate(record, undefined)"
            v-hasPermi="['tenant:openapi:clientResource:edit']"
          >
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:openapi:clientResource:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:openapi:clientResource:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listClientResource, delClientResource, exportClientResource } from '@/api/openapi/clientResource'
import { listResource } from '@/api/openapi/resource'
import CreateForm from './modules/CreateForm'

export default {
  name: 'ClientResource',
  components: {
    CreateForm
  },
  props: {
    clientInfo: {
      type: Object,
      default: () => {}
    },
    showInModal: {
      type: Boolean
    }
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        perms: null,
        clientId: null,
        numberLimit: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '资源名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'left'
        },
        {
          title: '标识(uri)',
          dataIndex: 'perms',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属租户',
          dataIndex: 'tenantName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '每分钟访问api的次数',
          dataIndex: 'numberLimit',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      resourceList: []
    }
  },
  filters: {},
  created() {
    this.queryParam.clientId = this.clientInfo.clientId
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询对外开放资源列表 */
    queryResourceList() {
      this.loading = true
      listResource({}).then((response) => {
        this.resourceList = response.rows
        this.loading = false
      })
    },
    /** 查询Client资源配置列表 */
    getList() {
      this.loading = true
      listClientResource(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        perms: undefined,
        clientId: undefined,
        numberLimit: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        zIndex: '9999',
        onOk() {
          return delClientResource(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        zIndex: '9999',
        onOk() {
          return exportClientResource(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
